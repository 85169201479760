
// import Swipper from './components/swiper/Swiper';
// import {slides} from './components/Swiper'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes,Route, useLocation } from 'react-router-dom';
// // import Navbar from './components/navbar/Navbar';
// import Navbara from './components/navbar/Navbara';
import Layout from './components/layout/Layout';

import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
// import Contacta from './pages/contact/Contacta';
// import AboutUs from './pages/aboutUs/AboutUs';
import AboutUs from './pages/aboutUss/AboutUs';
 import Motor from './pages/motor/Motor';
 import Marine from './pages/marine/Marine'
 import Health from './pages/health/Health'
 import Life from './pages/life/Life'
 import Auto from './pages/auto/Auto'
 import Travel from './pages/travel/Travel'
 import Property from './pages/property/Property'
 import Disability from './pages/disability/Disability'
 import Business from './pages/business/Business'
import Services from './pages/services/Services'
import { AnimatePresence } from 'framer-motion';





function App() {
  const location=useLocation()
  return (
    <div>
      {/* <BrowserRouter> */}
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={<Layout/>}>
          <Route index element={<Home />}/>
          <Route path='home' element={<Home />}/>
          <Route path='contact' element={<Contact />}/>
          {/* <Route path='contacta' element={<Contacta />}/> */}
          {/* <Route path='AboutUs' element={<AboutUs />}/> */}
          <Route path='AboutUs' element={<AboutUs />}/>
          <Route path='marine' element={<Marine/>}/>
          <Route path='motor' element={<Motor />}/>
          <Route path='health' element={<Health />}/>
          <Route path='life' element={<Life />}/>
          <Route path='auto' element={<Auto />}/>
          <Route path='travel' element={<Travel />}/>
          <Route path='property' element={<Property />}/>
          <Route path='disability' element={<Disability />}/>
          <Route path='business' element={<Business />}/>
          <Route path='Services' element={<Services/>}>
       
             
           </Route> 
        </Route>
      </Routes>
    </AnimatePresence>
      
      
      {/* <Swipper slides={slides}/> */}
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
