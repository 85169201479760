import React from 'react'
import './Buttonss.css'

const Buttonss = ({children, buttonssClass}) => {
  return (
    <div>
      <button className={`btn ${buttonssClass}`}>{children}</button>
    </div>
  )
}

export default Buttonss
