import React from 'react'
import './Travel.css'
import {motion} from 'framer-motion'
import {SecondaryTitle} from '../../components/title/Title'
import { containerVariants } from '../../components/variantss/Variantss'

const Travel = () => {
  return (
    <motion.div
    
    variants={containerVariants()}
    exit='exit'
    
    className='travel-wrapper'
    
    >
      <div className='travel-container container'>
        <SecondaryTitle>COMING SOON</SecondaryTitle>
      </div>
     
    </motion.div>
  )
}

export default Travel
