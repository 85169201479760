import React from 'react'
import './Title.css'
import {motion} from 'framer-motion'

export const Title = ({children, titleClass, delay=0}) => {
  return (
    <div className='title'>
      <motion.h1
      initial={{y:'100%', opacity:0}}
   
    whileInView={{y:'0', opacity:1}}
      
      transition={{type:'tween', duration:0.5, delay}}>
      {children}</motion.h1>
      </div>
  )
}




export const SecondaryTitle = ({children, titleClass, delay=0}) => {
  return (
    <div className='title'>
      <motion.h1
      initial={{x:'100%', opacity:0}}
   
    whileInView={{x:0, opacity:1}}
      
      transition={{type:'tween', duration:0.5, delay}}>
      {children}</motion.h1>
      </div>
  )
}


