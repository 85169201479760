import React from 'react'
import {useRef} from 'react'
import './Contact.css'
import {motion } from 'framer-motion'
import { fadeIn } from '../variants'
import { toast } from "react-toastify";

// import  { contacts } from  '../Data'
// import Button from '../../components/button/Button'
import Buttonss from '../../components/buttonss/Buttonss'
// import Card from '../../components/card/Card'
import emailjs from '@emailjs/browser';


// import { BsWhatsapp  } from 'react-icons/bs'
// import{MdOutlineEmail} from 'react-icons/md'
// import { FaLocationDot } from 'react-icons/fa6'
import { containerVariants } from '../../components/variantss/Variantss'
// import { HiOutlineMail } from 'react-icons/hi'
// import { IoMdTime } from 'react-icons/io'
// import {Title} from '../../components/title/Title'
// import Butting from '../../components/Buttons/Butting'


// const childVariants={
//   hidden:{
//     opacity:0
//   },
//   visible:{
//     opacity:1
//   }
// }



// const flipCardVariants={
//   hidden:{
//      x:'-50px', 
//     opacity:0
//   },
//   visible:{
//     x:'0', 
//     opacity:1,
//     transition:{
//       type:'tween',
//        duration:1,
//         delay:0.4,
//        ease:easeInOut,
     
//     }
//   },
  
// }



const Contact = () => {

    const form = useRef();

  // const [values, setValues] = useState({
  //   name:'',
  //   email:'',
  //   phone:'',
  //   subject:'',
  //   message:''
  // })
    
//     const handleChanges=(e)=>{
//      setValues({...values, [e.target.name]:[e.target.value]})
//    console.log(values)
//  }



  const handleSubmit=(e)=>{
    e.preventDefault()
    // e.target.reset()
    console.log(form.current.value);


    
  //   emailjs
  //     .sendForm(
  //       process.env.REACT_APP_EMAILJS_SERVICE_ID,
  //       'template_o4hcg5i',
  //       form.current,
  //       "R7VBdLEtXbIu259-M"
  //     )
  //     .then(
  //       (result) => {
  //         console.log("Message sent successfully");
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   e.target.reset();
    
  // }


 


// export const ContactUs = () => {
//   const form = useRef();

  // const handleSubmit = (e) => {
  //   e.preventDefault();

    emailjs
      .sendForm('service_6ggas0l', 'template_o4hcg5i', form.current, {
        publicKey: 'R7VBdLEtXbIu259-M',
      })
      .then(
        (result) => {
          toast.success('Message sent successfully!');
        },
        (error) => {
          toast.error( error.text);
        },
      );
      e.target.reset();
  };

  //emailjs service ID, template ID, and public key
  // const serviceId = 'service_6ggas0l';
  // const templateId = 'template_o4hcg5i';
  // const publicKey = 'R7VBdLEtXbIu259-M';

 
  // const templateParams={
    
  //   name:values.name,
  //   email:values.email,
  //   message:values.message,
  //   phone:values.phone,
  //   subject:values.subject,
    
  //   };
  
// send your mails using emailjs
// emailjs.send(serviceId, templateId, templateParams, publicKey)
// .then((response)=>{
//   console.log('email sent successful',response)
  
  
// })
// .catch((error)=>{
//   console.log('error sending email', error)
// })

  // const resetFunc = ()=>{
  //   setValues({name:'', email:'', subject:'', message:"", phone:''})
  // }

  return (
    <motion.div
    variants={containerVariants()}
    exit='exit'

    className='body-wrapper'
    >
      <header classname='header-wrapper'>
        <motion.div 
        variants={fadeIn('left', 0.3)}
        initial= 'hidden'
        whileInView={'show'}
        viewport={{once:false, amount:0.7}}
        
         className='header-container'>
          
           <img className='contact-image' src="contact-banner.jpg" alt='contact'/>
           {/* <h3 className='contact-banner-text'>Our team of experts <br/>are here <br/>to help you</h3>  */}
            
        </motion.div>
      </header>
      
        
        {/* <section className='contact-options-wrapper'>
          <Title>contact us </Title>
          <div className='contacts-map container'>
          <motion.div  */}
          
          {/* variants={flipCardVariants}
          initial='hidden'
          whileInView='visible' */}

            

          {/* className='contact-options-container'>

            <div className ='contact-option-container'>
              <BsWhatsapp className='i'/>
              <span className='text'> 08033499582</span> 
              <span className='text'><a href='https://api.whatsapp.com/send?phone=+2347013981891' target ='_blank' rel="noreferrer">Send a Message</a></span>
            </div> */}
{/* 
            <div className='contact-option-container'>
              <FaLocationDot className='i'/>
               <span className='text'>10 Zambezi Street, Wing   B, Upper Basement, WAEC Building Abuja</span> 
              <span className='text'><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d984.9590962829387!2d7.4902416!3d9.0786658!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0ba99c11f0c9%3A0xc32476b21fb9b12d!2sGlobal%20Alliance%20Insurance%20Brokers%20Limited!5e0!3m2!1sen!2sng!4v1728469312061!5m2!1sen!2sng" width="300" height="150" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='gaibLocation'></iframe></span>
            </div> */}

            {/* <div className='contact-option-container'>
              <MdOutlineEmail className='i' />
              {/* <span className='text'> info@gaib.com.ng</span> 
              <span className='text'><a href='mailto:tosinjaiyeoba@yahoo.com'>Send a Message</a></span>
            </div> */}

            {/* <div className='contact-option-container'>
              <IoMdTime  className='i'/>
              <span className='text'> Monday-Friday: 8am - 5pm</span>
            </div>
          </motion.div> */}
          
          {/* <motion.iframe 
        
        variants={fadeIn('up', 2)}
        initial= 'hidden'
        whileInView={'show'}
        viewport={{once:false, amount:0.7}} */}
        {/* src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d984.9590962829387!2d7.4902416!3d9.0786658!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0ba99c11f0c9%3A0xc32476b21fb9b12d!2sGlobal%20Alliance%20Insurance%20Brokers%20Limited!5e0!3m2!1sen!2sng!4v1728469312061!5m2!1sen!2sng" width="500" height="150" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='gaibLocation'></motion.iframe>
        

          </div>
         
         </section> */}


      <div className='form-wrapper'>
        <div className='form-container container'>
        <motion.div 
        
        variants={containerVariants('left', 0.3)}
        // variants={fadeIn('right', 0.3)}
        initial= 'hidden'
        whileInView='visible'
        // viewport={{once:false, amount:0.7}}
        className=' form form-container-left '>
            <h3>let us help you</h3>
            <p>We’re here to help answer your questions. Insurance and risk matters can be complicated, our experts are on hand to help inform you of every aspect regarding your situation. We take great pride in using our expertise for you and look forward to hearing from you.</p>
        </motion.div>
         
        <motion.div
        
        // variants={fadeIn('left', 0.3)}
        variants={containerVariants('right', 0.3)}
        initial= 'hidden'
        whileInView='visible'
        // viewport={{once:false, amount:0.7}}
        className='form form-container-right'>
          <form ref={form} onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col'>
                <label>Name*</label>
                <input type='text' placeholder='name' name='name' required ></input>
              </div>
              
              <div className='col'>
                <label>Email*</label>
                <input type='email' placeholder='email' name='email' required/>
              </div>
              
              <div className='col'>
                  <label>Phone number*</label>
                  <input type='number' placeholder='phone number' name='phone' required/>
                  
              </div>
              
              <div className='col'>
                  {/* <label htmlFor='subject'>Subject*</label> */}
                  <label>Subject*</label>
                  <input type='text' placeholder='subject' name='subject' required/>
                  
              </div>
              
              <div className='col'>
                  <label>Message*</label>
                  <textarea type='text' placeholder='type your message here' id='message' name='message' cols='30' row='5' required
                  ></textarea>
  
              </div>
                                      
              </div>
                      
              <Buttonss type='submit'>submit</Buttonss>
                         
            </form>
          
        </motion.div>
        </div>
       
      </div>

     
    </motion.div>
  )
}

export default (Contact)
