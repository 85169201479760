import React from 'react'
import './AboutUss.css'
import {motion} from 'framer-motion'
import { SecondaryTitle, Title } from '../../components/title/Title'
import { childVariants, containerVariants } from '../../components/variantss/Variantss'
import Carousel from '../../components/carousel/Carousel'
import { fadeIn } from '../variants'

const AboutUs = () => {
    return (
        <motion.div
        
        variants={containerVariants()}
        exit='exit'
        
        >
        
    
        
          <header className='header-wrapper'>
            <motion.div 
            
            variants={fadeIn('left', 0.3)}
            initial= 'hidden'
            whileInView={'show'}
            viewport={{once:false, amount:0.7}}
            
            className='header-container'>
              
               <img className='about-image' src="aboutus.jpg" alt='aboutus'/>
               {/* <h3 className='contact-banner-text'>Our team of experts <br/>are here <br/>to help you</h3>  */}
                
            </motion.div>
          </header>

          
        <section className='story-wrapper container'>
          <div className='story-container'>
            <SecondaryTitle>our story</SecondaryTitle>
            <motion.p
            
            variants={containerVariants('left', 0.3)}
             initial='hidden'
             whileInView='visible'
            
            >
            Global Allianz is an indigenous company licensed to review, advise and negotiate all insurance plans on behalf of clients.
               All clients, whether in the public or private sector, need experienced, objective and professional assistance to successfully meet the challenges of today's complex, competitive business environment..</motion.p>
          </div>
          <motion.div
          
          variants={containerVariants('right', 0)}
              initial='hidden'
              whileInView='visible'
          
          className='mission-vision-container'>
            <div className='mission'>
              <SecondaryTitle>mission</SecondaryTitle>
               <p>To exceed our public's expectations in our commitment to their financial success.</p>
            </div>
            <div className='vision'>
              <SecondaryTitle>vision</SecondaryTitle>
              <p>To deliver our services in a way that will make our clients our best marketers.</p>
            </div>

        </motion.div>

        </section>

        <section className='values-wrapper'>
    <Title className='value-heading'>our values</Title>
    <div className='values-container'>
     <motion.div
     
     variants={containerVariants('down',0)}
     initial='hidden'
     whileInView='visible'

      className='about-flipCard container'>
     
       <div className='flipWrapper'>
        <div className='flipCard-container'>
          <div className='front'><h3>Integrity</h3></div>
          <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores commodi fugit cum esse tempore neque.</p></div>
         </div>
       </div>  

       <motion.div 
       
       variants={childVariants}

       className='flipWrapper'>
         <div className='flipCard-container'>
            <div className='front'><h3>Proactiveness</h3></div>
            <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti pariatur tempore omnis! Maiores, culpa atque!</p></div>
         </div>
        </motion.div> 

        <motion.div 
        
        variants={childVariants}
        
        className='flipWrapper'>
            <div className='flipCard-container'>
              <div className='front'><h3>Loyalty</h3></div>
              <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae tempora corporis eum iure aliquam! Ullam.</p></div>
            </div>
        </motion.div>
          
         <motion.div 
         
         variants={childVariants}

         className='flipWrapper'> 
          <div className='flipCard-container'>
              <div className='front'><h3>Loyalty</h3></div>
              <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, sed atque! Animi voluptas laborum nihil.</p></div>
          </div>
        </motion.div>

     </motion.div>
     </div>
    </section>

    <Carousel/>

    </motion.div>
  )
}

export default AboutUs
