import React from 'react'
import './Navbar.css'
import './navoveride.css'
import { NavLink } from 'react-router-dom'

// import { NavLink } from 'react-router-dom'
const Header = () => {
  return (
    <header>
        <div className='container navbar-container'>
      <nav className="navbar fixed-top navbar-expand-lg bg-white">
     
  <div className="container-fluid">
    <a className="navbar-brand" href="home">
      <img src='logo.png' alt='logo'/>
    </a>
    <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav nav-pills mx-auto"
     
      >
        <li className="nav-item">
          <NavLink className="nav-link" aria-current="page" to="home">Home</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="AboutUs">About Us</NavLink>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" role="button" href='services' data-bs-toggle="dropdown" aria-haspopup='true'>
          Our Service
          </a>
          <ul className="dropdown-menu dropdown-menu-right" aria-labelledby='navbarDropdown'>
           <ul> 
              
             
              <li>  <NavLink className="dropdown-item" to="motor">motor insurance</NavLink></li>
              <li>  <NavLink className="dropdown-item" to="marine">marine insurance</NavLink></li>
              {/* <li>  <NavLink className="dropdown-item" to="contacta">contacta</NavLink></li> */}
               <li>  <NavLink className="dropdown-item" to="health">health insurance</NavLink></li>
              
           </ul>
            <ul>
              <li><NavLink className="dropdown-item" to="life">life insurance</NavLink></li>
              <li><NavLink className="dropdown-item" to="auto">auto insurance</NavLink></li>
              <li><NavLink className="dropdown-item" to="travel">travel insurance</NavLink></li>
            </ul>
            <ul>
              <li><NavLink className="dropdown-item" to="property">property insurance</NavLink></li>
              <li><NavLink className="dropdown-item" to="business">business insurance</NavLink></li>
              <li><NavLink className="dropdown-item" to="disability">disability insurance</NavLink></li>
            </ul>
          </ul>
        </li>
        
       

        <li className="nav-item">
          <NavLink className="nav-link" to="contact">Contact Us</NavLink>
        </li>

      </ul>
      <div className="nav-item email d-flex">
          <NavLink  to="webmail.gaib.com.ng">Staff Email</NavLink>
        </div>
    </div>
  </div>
 
</nav>
</div>
    </header>
  )
}

export default Header
