import React from 'react'
import './Motor.css'
import {containerVariants} from '../../components/variantss/Variantss'
import { motion } from 'framer-motion'
import { SecondaryTitle } from '../../components/title/Title'

const motor = () => {
  return (
    <motion.div
    
    variants={containerVariants()}
   exit='exit'

    className='motor-wrapper'

    >

      <div className='motor-container container'>
      <SecondaryTitle>COMING SOON</SecondaryTitle>
      </div>
     
    </motion.div>
  )
}

export default motor