import React from 'react'
import './Marine.css'
import {containerVariants} from '../../components/variantss/Variantss'
import { motion } from 'framer-motion'
import { SecondaryTitle } from '../../components/title/Title'


const Marine = () => {
  return (
    <motion.div
    
    variants={containerVariants()}
    initial='hidden'
    whileInView='visible'
    
    className='marine-wrapper'
    >

      <div className='marine-container container'>
          <SecondaryTitle>COMING SOON</SecondaryTitle>

      </div>
      
      
    </motion.div>
  )
}

export default Marine
