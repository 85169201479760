import React from 'react'
import './Footer.css'
import { NavLink } from 'react-router-dom'
import {MdOutlineEmail} from 'react-icons/md'
import {BsFacebook, BsWhatsapp} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer className='footer-wrapper'>
      <div className='footer-container container'>
        <div className='footer-item'>

          <div className='footer-column'>
            <h5 className='gaib'>GAIB</h5>
         <p> We are an indigenous company licensed to review, advise and negotiate all insurance plans on behalf of clients
         </p>
          </div>

          <div className='footer-column'>
            <h5>Permalinks</h5>
            <ul className='permalinks-option'>
              <li><NavLink to='/home'>Home</NavLink></li>
              <li><NavLink to='/AboutUs'>About Us</NavLink></li>
              <li><NavLink to='/services'>Services</NavLink></li>
              <li><NavLink to='/contact'>Contact Us</NavLink></li>
            </ul>

          </div>


          <div className='footer-column '>
            <h5>Contact Us</h5>
            <div className='footer-contact'>
            <div className="contact__option">
              <span> <MdOutlineEmail className='contact__option-icon'/></span>
              <span><a href='mailto:tosinjaiyeoba@yahoo.com'>Email Us</a></span>
            </div>

            <div className="contact__option">
              
                <span><BsWhatsapp className='contact__option-icon'/></span>
                {/* <span> <a href='https://api.whatsapp.com/send?phone=+2347013981891' target ='_blank' rel="noreferrer">WhatApp</a></span> */}
                <span> <a href='https://wa.me/+2347013981891' target ='_blank' rel="noreferrer">WhatApp</a></span>
              
            </div>

            <div className="contact__option">
              <span> <BsFacebook className='contact__option-icon'/></span>
              <span><a href='https://m.me/jayeoba.gbemisola' target ='_blank' rel="noreferrer">Facebook</a></span>
            </div>
            <div>
              <span></span>
              <span>8am - 6pm (Monday - Friday)</span>
            </div>
          </div>
          </div>
        </div>
       
      </div>
      <div className='footer-copyright'>
        <div className='copyright container'>
        <small>&copy; 2024</small>
      
        <script>
          document.write(new Date().getFullYear());
         
        </script> 
        <hr></hr>
        <small> Global Allianz Insurance Brokers.</small>
        <hr></hr>
        <small> All rights reserved</small>
        </div>
       
      </div>
    </footer>
  )
}

export default Footer
