import React from 'react'
import './Swiper.css'


const Swiper = () => {
  return (
    <div>
        <div id="cusCarousel" className="carousel slide" data-bs-ride='carousel' data-bs-wrap ='true' data-bs-interval='5000'>
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#cusCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#cusCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#cusCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
        <div>
            <img src="motor.jpg" className="d-block w-100" alt="motor"/>
        </div>
      
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>motor insurance</h5>
        <p>Some representative placeholder content for the first slide.</p>
      </div> */}
    </div> 
    <div class="carousel-item active">
      <img src="car.jpg" className="d-block w-100" alt="marine"/>
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div> */}
    </div>
    <div class="carousel-item">
      <img src="marine.jpg" className="d-block w-100" alt="marine"/>
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Second2 slide label</h5>
        <p>Some representative placeholder content for the second slide.</p>
      </div> */}
    </div>
  
    <div class="carousel-item">
      <img src="insurance.jpg" classclass="d-block w-100" alt="insurance"/>
      {/* <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Some representative placeholder content for the third slide.</p>
      </div> */}
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#cusCarousel" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#cusCarousel" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Swiper
