import React from 'react'
import './Business.css'
import {motion} from 'framer-motion'
import {SecondaryTitle} from '../../components/title/Title'
import { containerVariants } from '../../components/variantss/Variantss'

const Business = () => {
  return (
    <motion.div
    
    variants={containerVariants()}
    exit='exit'

    className='business-wrapper'

    >
    <div className='business-container container'>
        <SecondaryTitle>COMING SOON</SecondaryTitle>

    </div>
      
    </motion.div>
  )
}

export default Business
