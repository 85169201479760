export const Services = [
    {
        img:'',
        title :'insurance broking services',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita enim atque aliquam quaerat quis',
        button:'learn more'

    },{
        img:'',
        title :'insurance broking services',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero',
        button:'learn more'

    },{
        img:'',
        title :'insurance broking services',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita enim atque aliquam quaerat quis',
        button:'learn more'

    },{
        img:'',
        title :'insurance broking services',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita enim atque aliquam quaerat quis',
        button:'learn more'

    }
]
export const Values = [
    {
        img:'',
        title :'Integrity',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita enim atque aliquam quaerat quis'
      

    },
    {
        img:'',
        title :'Integrity',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita enim atque aliquam quaerat quis'
        

    },
    {
        img:'',
        title :'Integrity',
        desc: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Libero expedita enim atque aliquam quaerat quis'
        

    },
]
export const contacts = [
    {
        img:"",
        title:"Head Office",
        desc:"Global Alliance Insurance     Brokers  10 Zambezi Street, Wing   B, Upper Basement, WAEC Building Abuja"
    },
    {
        img:"",
        title:"Business Hour",
        desc:"Monday-Friday: 8am - 5pm."
    },
    {
        img:"",
        title:"Phone",
        desc:"Abuja Office: 08033499582"
    },
    {
        img:"",
        title:"Email",
        desc:"info@gaib.com.ng"
    },
    
   

]