
export const containerVariants = (direction, delay)=> {
    return{
        hidden:{
            x: direction==='left'?'-35%': direction==='right'?'35%': 0,
            y: direction==='up'?'-50%': direction==='down'?'50%':0,
            
        },
        visible:{
            x: 0,
            y: 0,
      opacity: 1,
      transition:{
            type:'tween',
            duration:1,
            delay:delay,
            //   ease:easeInOut,
            ease:[0.25, 0.25,0.25,0.75],
            when:'beforeChildren',
            staggerChildren: 0.6
          
        }
    },
    exit:{
        x:'-100vw',
       transition:{
        // ease:easeInOut,
        duration:0.8,
       }
    }
    
  }
  
 }



 export const childVariants={
    hidden:{
      opacity:0
    },
    visible:{
      opacity:1
    }
}

