import React from 'react'
import './Disability.css'
import {containerVariants} from '../../components/variantss/Variantss'
import { motion } from 'framer-motion'
import { SecondaryTitle } from '../../components/title/Title'

const Disability = () => {
  return (
    <motion.div
    
    variants={containerVariants()}
    exit='exit'
    
    className='disable-wrapper'
    >
      <div className='container disable-container'>
         <SecondaryTitle>COMING SOON </SecondaryTitle>
      </div>
     
    </motion.div>
  )
}

export default Disability
