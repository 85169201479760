import React from 'react'
import './Home.css'
import { NavLink } from 'react-router-dom'
import '../../components/flippingCard/Flipping.css'
// import {Link} from 'react-router-dom'
import Swipper from '../../components/swipers/Swiper'
import Card from '../../components/card/Card'
import Button from '../../components/button/Button'
import { homeData } from '../../components/data/Data'
import { Services } from '../Data'
// import { Values } from '../Data'
 import Buttonss from '../../components/buttonss/Buttonss'

import {Title} from '../../components/title/Title'
import { easeIn, easeInOut, motion } from 'framer-motion'
// import { containerVariants } from '../../components/variantss/Variantss'


 const containerVariants = (direction, delay)=> {
  return{
      hidden:{
          x: direction==='left'?'-50%': direction==='right'?'50': 0,
          y: direction==='up'?'-50%': direction==='down'?'50%':0,
          
      },
      visible:{
          x: 0,
          y: 0,
    opacity: 1,
    transition:{
          type:'tween',
          duration:1,
          delay:delay,
          //   ease:easeInOut,
          ease:[0.25, 0.25,0.25,0.75],
          when:'beforeChildren',
          staggerChildren: 0.4
        
      }
  },
  exit:{
      x:'-100vw',
     transition:{
      // ease:easeInOut,
      duration:0.8,
     }
  }
  
}

}



const flipCardVariants={
  hidden:{
     x:'-50px', 
    opacity:0
  },
  visible:{
    x:'0', 
    opacity:1,
    transition:{
      type:'tween',
       duration:1,
        delay:0.4,
       ease:easeIn,
     
    }
  },
  exit:{
    y:'50%',
    opacity:0
  }
}

// const buttonVariants={
//   hover:{
//     scale:1.1,
//     transition:{
//       duration:0.3,
//       yoyo:10
//     }
//   }
// }

// const exitVariants={
//   exit:{
//     x:'-100vw',
//     transition:{
//        duration:0.8,
//       ease:easeInOut
//   }

// }
// }

const Home = (cardClass, buttonssClass, titleClass) => {
  return (
    
  
    <motion.div 
    variants={containerVariants('right', 0.3)}
    exit='exit'
    transition='transition'
    

    className='bodyy'>
      
      <Swipper/>
      <section className='history-wrapper'>
        <div className='history-container container'>
          <Title>WHO WE ARE</Title>
          <motion.p
          
                // variants={containerVariants('right', 0.3)}
                variants={flipCardVariants}
                initial='hidden'
                whileInView='visible'  

          >Global Allianz is an indigenous company licensed to review, advise and negotiate all insurance plans on behalf of clients.
          All clients, whether in the public or private sector, need experienced, objective and professional assistance to successfully meet the challenges of today's complex, competitive business environment.</motion.p>
        </div>
      </section>

     <section className='home-service-wrapper'>
      <div className='home-service-container container'>
        <Title titleClass='home-service-heading'>world-class service. top notch solutions.</Title>
        <div className='home-services'>
          {
            Services.map((service)=>{
                return(
                <motion.div

                //variants={containerVariants('right', 0.3)}
                variants={flipCardVariants}
                initial='hidden'
                whileInView='visible'

                className='home-service' key={service.img}
                
                 >
                  <div>{service.img}</div>
                  <h3>{service.title}</h3>
                  <p>{service.desc}</p>
                  <Buttonss buttonssClass='home-service-button'><a href='services'>learn more </a> </Buttonss>
              </motion.div>
              
                )
            })
          }
          
        </div>
      </div>

     </section>
     
     <div className='home-question-wrapper'> 
      <div className='home-question-container container'>
        <h3 className='home-question-h3'>question about issurance issue? &nbsp; ask our expert</h3>
        
        <button className='btn'><a href='contact'>contact us</a>
        </button>
        {/* <Buttonss buttonssClass='homeServiceButton'><a href='contact'>contact us</a>
        </Buttonss> */}
        
      </div>
     </div>
   
    <section >
    <Title titleClass='home-value-heading'>our values</Title>
    
     <motion.div
     
     variants={flipCardVariants}
     initial='hidden'
     whileInView='visible'
      
     className='homeFlipCard container'>
     
          <div className='flipWrapper'>
           <div className='flipCard-container'>
            <div className='front'><h3>Integrity</h3></div>
            <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores commodi fugit cum esse tempore neque.</p></div>
         </div>
       </div>  
        
       <div className='flipWrapper'  delay={0.4}>
         <div className='flipCard-container'>
          <div className='front'><h3>Proactiveness</h3></div>
          <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti pariatur tempore omnis! Maiores, culpa atque!</p></div>
         </div>
        </div> 

        <div className='flipWrapper' delay={0.6}>
         <div className='flipCard-container'>
          <div className='front'><h3>Loyalty</h3></div>
          <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae tempora corporis eum iure aliquam! Ullam.</p></div>
         </div>
          </div>
          
         <div className='flipWrapper'  delay={0.8}> 
         <div className='flipCard-container'>
          <div className='front'><h3>Loyalty</h3></div>
          <div className='back'><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, sed atque! Animi voluptas laborum nihil.</p></div>
         </div>
        
      </div>
     </motion.div>
    </section>
     
     <section className="home-work-wrapper">
      <div className="home-work-container container">
        <Title>let's work together</Title>
        <motion.h3
         initial={{opacity:0}}
          //   animate={{y:'0'}}
          whileInView={{opacity:1}}
            exit={{opacity:0}}
            transition={{type:'tween', duration:1, delay:0.5}}
        >We work as a powerful united team and give our clients the highest quality service possible.</motion.h3>
        <button className='btn'><a href='contact'>Engage Us
       </a> </button>
      </div>
            
     </section>
    
    </motion.div> 
   
  
  )
}

export default Home
