import React from 'react'
import './Auto.css'
import {motion} from 'framer-motion'
import {SecondaryTitle} from '../../components/title/Title'
import { containerVariants } from '../../components/variantss/Variantss'

const Auto = () => {
  return (
    <motion.div
    
    variants={containerVariants()}
   exit='exit'

    className='auto-wrapper'>
      <div className=' auto-container container'>
        <SecondaryTitle>COMING SOON</SecondaryTitle>
      </div>
     
    </motion.div>
  )
}

export default Auto
