import React from 'react'
import './Life.css'
import {containerVariants} from '../../components/variantss/Variantss'
import { motion } from 'framer-motion'
import { SecondaryTitle } from '../../components/title/Title'

const Life = () => {
  return (
    <motion.div
    
    variants={containerVariants()}
    initial='hidden'
    whileInView='visible'

    className='life-wrapper'
    >
      <div className ='life-container container'>
         <SecondaryTitle>COMING SOON</SecondaryTitle>
      </div>
      
    </motion.div>
  )
}

export default Life
