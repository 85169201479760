import './Carousel.css'
// import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import { Swiper, SwiperSlide } from 'swiper/react';

const Carousel = () =>{
    return(
        
              <Swiper className='container'
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={10}
                slidesPerView={4}
                autoplay={{
                    delay:2500,
                    disableOnInteracton:false,
                }}
                breakpoints={{
                  310:{
                    width:310,
                    slidesPerView:1,},

                  400:{
                    width:400,
                    slidesPerView:1,},

                    768:{
                      width:768,
                      slidesPerView:2},

                                 
              }}
                // navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                
               
              >
                <section className='swiper-wrapper container'>
                    <div className='slides'>
                        <SwiperSlide><img src='pencom3.jpg' alt='pencom'/></SwiperSlide>
                        <SwiperSlide><img src='efcc3.jpg' alt='efcc'/></SwiperSlide>
                        <SwiperSlide><img src='tetfund1.jpg' alt='tetfund'/></SwiperSlide>
                        <SwiperSlide><img src='waec1.jpg' alt='efcc'/></SwiperSlide>
                        <SwiperSlide><img src='firs3.jpg' alt='firs'/></SwiperSlide>
                        <SwiperSlide><img src='nnpc3.jpg' alt='nnpc'/></SwiperSlide>
                        <SwiperSlide><img src='police3.jpg' alt='police'/></SwiperSlide>
                        {/* <SwiperSlide><img src='nnpc1.jpg' alt='nnpc'/></SwiperSlide> */}
                        <SwiperSlide><img src='prison3.jpg' alt='prison'/></SwiperSlide>
                    </div>
                    
                </section>
                
              </Swiper>
           
    )
}
export default Carousel





